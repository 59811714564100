@import "../../variables";

:root {
  --swiper-theme-color: #808080;
  --swiper-navigation-size: 20px;
}

.slider_wrappper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin: 50px;
  position: relative;
  z-index: 0;
}
.slide_description {
  text-align: left;
  font-size: 14px;
  font-family: $title-font;
  color: $main-black;
}

.slide_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 600px;
  overflow: hidden;
  position: relative;
  padding-bottom: 40px;
}

.slide_img img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.swiper-button-prev,
.swiper-button-next {
  background: none;
  color: #000;
  width: auto;
  height: auto;
  font-size: 20px;
}

.swiper-button-next {
  right: 10px;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 16px;
}

.swiper-pagination-bullet {
  background: transparent;
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 768px) {
  :root {
    --swiper-navigation-size: 16px;
  }
  .slider_wrappper {
    margin: 5px;
  }

  .slide_description {
    font-size: 12px;
  }

  .slide_img {
    max-height: 400px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 16px;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 14px;
  }

  .swiper-pagination-bullet {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    :root {
      --swiper-navigation-size: 14px;
    }
    .slide_img {
      max-height: 300px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      font-size: 14px;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 12px;
    }
  }
}
