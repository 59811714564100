@import "../../variables";

.navlink {
  text-decoration: none;
}
.container {
  width: 900px;
  margin: 30px auto;
}

@media (max-width: 950px) {
  .container {
    width: 90%;
    max-width: 600px;
  }
}

@media (max-width: 576px) {
  .container {
    width: 90%;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 85%;
    max-width: 400px;
  }
}
