@import "../../variables";

.nav {
  padding: 20px 0;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid $main-black;
  letter-spacing: normal;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .navrow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .logo {
    color: $main-black;
    font-size: 26px;
    text-decoration: none;
    font-family: $logo-font;
    font-weight: 700;
    letter-spacing: -1.4px;
    word-spacing: 0.3em;
  }

  .navlist {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    list-style: none;
    padding: 0;

    .navlist__item {
      font-size: 14px;
      font-weight: 500;
      font-family: $p-header-font;
      letter-spacing: 0.8px;

      a {
        color: $main-black;
        text-decoration: none;
        transition: opacity 0.3s ease;

        &:not(.activeLink):hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.activeLink {
  position: relative;
}
.activeLink::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  height: 2px;
  width: 100%;
  background-color: #252525;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2 rem;
}

.mobile_btn {
  display: none;
}

@media (max-width: 576px) {
  .mobile_btn {
    display: block;
    position: absolute;
    left: 10px;
    top: 25px;
    font-size: 25px;
    z-index: 1000;
  }
  .navlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 0.7s cubic-bezier(0.4, 0, 1, 1);
    opacity: 0;
    pointer-events: none;
  }

  .navlist a {
    font-size: 18px;
  }

  .navlist__item a {
    color: white !important;
  }
  .mobile_active {
    left: 0;
    opacity: 1;
    z-index: 1;
    pointer-events: auto;
    color: white;
  }
  .activeLink::before {
    background-color: white;
  }
  .closeIcon_Active {
    color: white;
  }
}

@media (max-width: 500px) {
  .logo {
    font-size: 1.25rem !important;
    margin-right: 15px;
    z-index: 10;
    position: relative;
  }
}
