@import "../../variables";

.section {
  .container {
    max-width: 1200px;
    margin: 30px auto;
    padding: 0 15px;

    .projects {
      padding: 0;
      list-style: none;
      justify-content: center;
    }
  }
}

@media (max-width: 576px) {
  .section {
    padding: 10px 0;
  }

  .container {
    max-width: 100%;
    padding: 0 10px;
  }
}
