@import "../../variables";

.section {
  padding: 40px 0;
  font-family: $p-header-font;

  .container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 45px;
    margin-bottom: 50px;

    .title1 {
      margin: 10px 0;
      font-family: $title-font;
      font-size: 2rem;
    }
    .name_title {
      font-size: 1.5rem;
      font-family: $text-font;
    }
    p {
      color: $text-color;
      margin: 5px 0;
      font-family: $text-font;
      font-size: 1rem;
      a {
        text-decoration: none;
        color: $text-color;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    &::after {
      content: "";
      display: block;
      background-color: $text-color;
      width: 250px;
      height: 1px;
    }
  }
}

@media (max-width: 576px) {
  .container {
    margin-left: 20px;
    margin-bottom: 20px;

    .title1 {
      font-size: 1.5rem;
    }

    .name_title {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
      margin: 2px 0;
    }
  }
}
