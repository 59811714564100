@import "../../variables";

.about {
  padding: 20px;
  text-align: center;
  font-family: $p-header-font;
  color: $main-black;
  .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5em;
    margin-bottom: 5vh;
    margin-left: 1.5vw;
    .about_text {
      text-align: justify;
      margin-top: 15vh;
      margin-right: 7vw;
      p {
        font-size: 1.1rem;
        color: $text-color;
        font-family: $text-font;
        line-height: 1.6;
      }
    }
    .img_div {
      width: 30vw;
      height: auto;
      margin-top: 5vh;
      overflow: hidden;

      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        transition: transform 0.7s ease;
      }
      .img_div:hover img {
        transform: scale(1.3);
      }
    }
  }
}

.img_div img:hover {
  transform: scale(1.2) translateX(-25px);
}

@media (max-width: 800px) {
  .about {
    padding: 15px;
    .container {
      grid-template-columns: 1fr;
      margin: 0;
      gap: 1em;
      .img_div {
        width: 80vw;
        margin: 0 auto;
      }
      .about_text {
        margin-top: 1px;
        margin-right: 0;
        p {
          font-size: 1rem;
          margin: 10px 0;
        }
      }
    }
  }
}
