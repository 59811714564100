@import "../../variables";

.notFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60vh;
  padding: 0 20px;
  h1 {
    font-size: 36px;
    color: $main-black;
    font-family: $title-font;
  }
  p {
    font-size: 18px;
    color: $text-color;
    font-family: $p-header-font;
  }
  .backLink {
    font-size: 16px;
    color: $text-color;
    text-decoration: none;
    font-family: $p-header-font;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 48px;
  }

  p {
    font-size: 16px;
  }

  .backLink {
    font-size: 14px;
  }
}
