@import "./../../variables.scss";
ul {
  padding-left: 0px;
}
.navlink {
  text-decoration: none;
}
.project {
  overflow: hidden;

  .projectImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.9s cubic-bezier(0.4, 0, 1, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
}

.increased {
  transform: scale(1.2);
  transition: transform 0.7s ease-in-out;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
}

.increasedImg {
  max-width: 90%;
  max-height: 90%;
  z-index: 1000;
  transform: scale(1);
  transition: transform 1s ease-in-out;
}
