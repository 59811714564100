@import "../../variables";

.footer {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
  margin-top: auto;
  padding: 40px 0 30px;
  background-color: $main-black;
  color: white;
  margin-right: 0;

  .footer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;

    .social {
      display: flex;
      column-gap: 30px;
      align-items: center;
      list-style-type: none;

      img {
        width: 25px;
        &:hover {
          width: 22px;
          transition: width 0.2s ease;
        }
      }
    }
  }

  .copyright {
    font-family: $logo-font;
    font-size: 14px;
    font-weight: 300;

    p + p {
      margin-top: 0.5em;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 20px 0 15px;
  }
  .footer__wrapper {
    .social {
      column-gap: 15px;
      img {
        width: 20px;
      }
    }
    .copyright {
      font-size: 12px;
      text-align: center;
    }
  }
}
