@import "../../variables";

.backToTopButton {
  position: fixed;
  bottom: 30px;
  right: 40px;
  height: 30px;
  width: 30px;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: scale(0.98);
  }
}
.up_img {
  width: 20px;
  height: auto;
}

@media (max-width: 576px) {
  .backToTopButton {
    bottom: 20px;
    right: 30px;
    height: 40px;
    width: 45px;
    font-weight: bolder;
  }
}
